import { map, Observable, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@ggp/generic/shared/config/app';
import { Mapbox } from '@ggp/generic/shared/util/models';

interface SearchCriteria {
  searchText: string;
  country: string;
  address: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class GeoCodingService {
  private readonly httpClient = inject(HttpClient);

  constructor(@Inject(APP_CONFIG) private environment: AppConfig) {}

  getLocationByText({ searchText, country, address }: SearchCriteria): Observable<Observable<number[]> | null> {
    const query = [address, searchText].filter(Boolean).join(', ');

    return this.httpClient
      .get<Mapbox>(`${this.environment.endpoints.mapbox.geocoding_url}/${query}.json`, {
        params: {
          access_token: this.environment.endpoints.mapbox.access_token,
          country,
          types: 'country,region,postcode,place,address',
          limit: 1,
        },
      })
      .pipe(map(({ features }) => (features.length ? of(features[0].center) : null)));
  }
}
