import { Apollo } from 'apollo-angular';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_CONFIG } from '@ggp/generic/shared/config/app';
import { SORT_FILTERS_TOKEN } from '@ggp/generic/shared/config/token';
import { DateRange, Filters, Range, Scope } from '@ggp/generic/shared/util/models';

import { Filter, FilterSelected } from './models';
import { getTotalItemsGQL } from './schemas/projectsTotalNumber.schema';

export enum AllowedParentKey {
  EXECUTION_SITE = 'execution_site',
  SUBSCRIPTION_DATA = 'subscription_data',
  ACTIVITIES = 'activities',
  KEYWORD = 'keyword',
  PROCEDURE_TYPE = 'procedure_type',
  TAGS = 'tags',
  LANGUAGE = 'language',
  PROJECT_SIZE = 'project_size',
  SCOPES = 'scopes',
  LAST_UPDATE = 'last_update',
  DEADLINE = 'deadline',
  START_DATE = 'start_date',
  CLASSES = 'classes',
  CPVS = 'cpvs',
  PROJECT_TYPE = 'project_type',
  BUILDING_TYPE = 'building_type',
  READ_UNREAD = 'read_unread',
  IS_DEMO_PROFILE = 'isDemoProfile',
}

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  readonly #apollo = inject(Apollo);
  readonly #httpClient = inject(HttpClient);
  readonly #router = inject(Router);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #sortConfig = inject(SORT_FILTERS_TOKEN, { optional: true });
  readonly showCurrentStageOnlyAppConfig = inject(APP_CONFIG).features.showCurrentStageOnly;

  filtersSubject$ = new BehaviorSubject<Filter>({} as Filter);
  selectedProfileKeywords$ = new BehaviorSubject<string[]>([]);
  selectedProfileKeywordsObs$ = this.selectedProfileKeywords$.asObservable();
  #filter: Filter = {} as Filter;
  private readonly notificationsFiltersSubject$ = new BehaviorSubject<boolean>(false);

  set notificationsFiltersSubject(data: boolean) {
    this.notificationsFiltersSubject$.next(data);
  }

  get notificationsFiltersSubject(): Observable<boolean> {
    return this.notificationsFiltersSubject$.asObservable();
  }

  constructor() {
    this.#filter.filtersSelected = [];
  }

  addFilter(value: FilterSelected) {
    const existedFilter = this.#filter?.filtersSelected?.some(
      filterSelected => filterSelected.parentKey === value.parentKey && filterSelected.value === value.value,
    );

    if (!existedFilter && this.isAllowedToAdd(value.parentKey)) {
      this.#filter?.filtersSelected?.push(value);
      this.submitValues(this.#filter);
    }
  }

  private isAllowedToAdd(keyParent: string) {
    return Object.values(AllowedParentKey).includes(keyParent as AllowedParentKey);
  }

  removeFilter(filter: FilterSelected, shouldSubmitValues = true) {
    const pos = this.#filter?.filtersSelected?.findIndex(
      item => item.parentKey === filter.parentKey && JSON.stringify(item.value) === JSON.stringify(filter.value),
    );
    if (pos > -1) {
      this.#filter?.filtersSelected?.splice(pos, 1);
    }

    if (!this.#filter?.filtersSelected.some(({ parentKey }) => parentKey === 'subscription_data')) {
      const demoFilterIndex = this.#filter?.filtersSelected.findIndex(({ parentKey }) => parentKey === 'isDemoProfile');
      demoFilterIndex > -1 && this.#filter?.filtersSelected?.splice(demoFilterIndex, 1);
    }

    shouldSubmitValues && this.submitValues(this.#filter);
  }

  removeAllByKeyParent(keyParent: string) {
    const items = this.#filter?.filtersSelected?.filter(i => i.parentKey === keyParent);
    if (items) {
      items.forEach(item => {
        const pos = this.#filter?.filtersSelected?.findIndex(i => i.value === item.value);
        if (pos > -1) this.#filter?.filtersSelected?.splice(pos, 1);
      });
    }
    this.submitValues(this.#filter);
  }

  addListByKeyParent(list: FilterSelected[] | FilterSelected) {
    if (Array.isArray(list)) {
      list.forEach(item => {
        this.#filter.filtersSelected?.push(item);
      });
    } else {
      this.#filter.filtersSelected?.push(list);
    }

    this.submitValues(this.#filter);
  }

  clearAll() {
    this.#filter.filtersSelected = [];
    this.submitValues(this.#filter);
  }

  updateFilter(filter: FilterSelected) {
    const pos = this.#filter?.filtersSelected?.findIndex(item => item.parentKey === filter.parentKey);
    if (pos > -1) {
      this.#filter.filtersSelected[pos] = filter;
      this.submitValues(this.#filter);
    } else {
      this.addFilter(filter);
    }
  }

  editFilter(filters: FilterSelected[], value: boolean) {
    filters?.map(filter => {
      const isAnyFilterSelected = this.#filter?.filtersSelected?.some(item => item.parentKey === filter.parentKey && item.value === filter.value);
      filter.currentStageOnly = isAnyFilterSelected ? value : filter.currentStageOnly;
    });
    this.submitValues(this.#filter);
  }

  getTreeData(type = 'region', language = 'en') {
    const filtersUrl = `assets/filters/${type}/${type}-${language}.json`;

    return this.#httpClient.get(filtersUrl).pipe(
      map(data => {
        const filterData = data as any[];
        this.#sortFilters(filterData, type);

        return filterData;
      }),
    );
  }

  private submitValues(list: Filter) {
    this.filtersSubject$.next(list);
  }

  getCopyOfFilters() {
    return [...this.#filter.filtersSelected];
  }

  getTotalProjectsNumber({ projectType, favorite, deleted }: { projectType: string; favorite: boolean; deleted: boolean }) {
    const currentFilters = {
      ...this.getFiltersValues(),
      favorite,
      deleted,
    };

    return this.#apollo.query<{ getTotalItemsQuery: number }>({
      query: getTotalItemsGQL,
      variables: {
        projectType: projectType,
        filters: currentFilters,
        language: localStorage.getItem('currentLanguage') || 'en',
      },
      fetchPolicy: 'no-cache',
    });
  }

  getTreeFilterValue(filtersSelection: FilterSelected[], filterName: string) {
    const filterIds: any = [];
    filtersSelection
      ?.filter(i => i.parentKey === filterName)
      .map(filter => {
        filterIds.push(filter.value);
        filter.children?.map(child => {
          if (!filterIds.includes(child)) {
            filterIds.push(child);
          }
        });
      });
    return filterIds;
  }

  getProfileValue(): number[] {
    const folder = this.#activatedRoute.snapshot.queryParamMap.get('folder');
    const subFolderId = this.#activatedRoute.snapshot.queryParamMap.get('subFolderId');
    const profilesFilter = this.#filter?.filtersSelected?.filter(({ parentKey }) => parentKey === 'subscription_data').map(({ value }) => +value);

    if (profilesFilter?.length) {
      return profilesFilter;
    }

    if (subFolderId && folder !== 'my_searches') {
      return [+subFolderId];
    }

    return [];
  }

  getIsDemoProfile(): boolean {
    const filtersSelection = this.#filter?.filtersSelected;
    const isDemoProfileFilter = filtersSelection?.filter(i => i.parentKey === 'isDemoProfile');
    const isDemoParam = this.#activatedRoute.snapshot.queryParamMap.get('isDemo') === 'true';

    return isDemoProfileFilter?.length === 1 ? isDemoProfileFilter[0].value : isDemoParam;
  }

  getFiltersValues(filter?: FilterSelected[]): Filters {
    const filtersSelection = filter ?? this.#filter?.filtersSelected;
    const readUnreadFilter = filtersSelection?.filter(i => i.parentKey === 'read_unread');

    return {
      keywords: filtersSelection?.filter(i => i.parentKey === 'keyword').map(filter => filter.value as string),
      stages: filtersSelection
        ?.filter(i => i.parentKey === 'procedure_type')
        .map(filter => {
          return {
            stage: filter.value as string,
            currentStageOnly: filter.currentStageOnly !== undefined && this.showCurrentStageOnly('procedure_type') ? filter.currentStageOnly : false,
          };
        }),
      tags: filtersSelection?.filter(i => i.parentKey === 'tags').map(filter => filter.value as string),
      languages: filtersSelection?.filter(i => i.parentKey === 'language').map(filter => filter.value as string),
      regions: this.getTreeFilterValue(filtersSelection, 'execution_site'),
      scopes: filtersSelection?.filter(i => i.parentKey === 'scopes').map(filter => filter.value) as Scope[],
      activities: this.getTreeFilterValue(filtersSelection, 'activities'),
      cpvs: this.getTreeFilterValue(filtersSelection, 'cpvs'),
      lastUpdateDate: filtersSelection?.filter(i => i.parentKey === 'last_update').map(filter => this.getDateOnly(filter) as DateRange)[0],
      startDate: filtersSelection?.filter(i => i.parentKey === 'start_date').map(filter => this.getDateOnly(filter) as DateRange)[0],
      deadlineDate: filtersSelection?.filter(i => i.parentKey === 'deadline').map(filter => this.getDateOnly(filter) as Range)[0],
      classes: this.getTreeFilterValue(filtersSelection, 'classes'),
      buildingTypes: this.getTreeFilterValue(filtersSelection, 'building_type'),
      projectTypes: filtersSelection?.filter(i => i.parentKey === 'project_type').map(filter => filter.value as string),
      profiles: this.getProfileValue(),
      isDemoProfile: this.getIsDemoProfile(),
      ...(readUnreadFilter?.length === 1 ? { read: readUnreadFilter[0].value } : {}),
    };
  }

  showCurrentStageOnly(filterType?: string, projectType?: string): boolean {
    const projectTypeWithFallback = projectType ?? this.#router.routerState.snapshot.root.queryParams['projectType'];
    return filterType === 'procedure_type' && projectTypeWithFallback === 'PUBLIC_TENDER' && !!this.showCurrentStageOnlyAppConfig;
  }

  #sortFilters(filterData: any[], type: string) {
    if (this.#sortConfig) {
      this.#sortConfig.forEach(filter => {
        if (filter.type === type) {
          const index = filterData.findIndex((item: any) => item.key === filter.key);

          if (index !== -1) {
            const [item] = filterData.splice(index, 1);
            filterData.splice(filter.position, 0, item);
          }
        }
      });
    }
  }

  private getDateOnly(filter: FilterSelected): { [key: string]: number | string } {
    const { maxValue, minValue } = filter.value;

    return {
      ...filter.value,
      maxValue: maxValue ? this.getLocalDate(new Date(maxValue)).toISOString().split('T')[0] : null,
      minValue: minValue ? this.getLocalDate(new Date(minValue)).toISOString().split('T')[0] : null,
    };
  }

  private getLocalDate(date: Date): Date {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  }

  private getRangeFilter(filtersSelection: FilterSelected[], key: string): Range {
    return filtersSelection?.filter(i => i.parentKey === key).map(filter => filter.value as Range)[0];
  }
}
