import { InjectionToken } from '@angular/core';
import { DEFAULT_BRAND_CONFIG } from '@ggp/ebp-bo/shared/constants';

import { PlatformConfig } from './platform-config.interface';

export const DEFAULT_PLATFORM_CONFIG: PlatformConfig = {
  constructionProjectsColumns: ['select', 'infos', 'project-type', 'description', 'region', 'start-date', 'last-update'],
  constructionProjectDetailsColumns: ['authority', 'construction-cost', 'surface', 'construction-start'],
  profileDetailsRoutes: DEFAULT_BRAND_CONFIG,
};

export const PLATFORM_CONFIG_TOKEN = new InjectionToken<PlatformConfig>('platform-config', {
  providedIn: 'root',
  factory: () => DEFAULT_PLATFORM_CONFIG,
});
