import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import {
  AppNotification,
  Notification,
  NotificationMethods,
  NotificationTimeframe,
  NotificationType,
} from '@ggp/generic/shared/services';
import { ShareProject, ShareProjectResult } from '@ggp/generic/shared/util/models';

import { NotificationQuery } from '../query/notificationQuery';
import {
  getNotificationsCountQueryGQL,
  getNotificationsQueryGQL,
  markNotificationSeenMutationGQL
} from '../schema/app-notification-queries.schema';
import { shareProjectMutationGQL } from '../schema/notification.schema';

@Injectable({
  providedIn: 'root',
})
export class EbpNotificationService implements NotificationMethods {
  readonly #apollo = inject(Apollo);
  notifications!: Notification[];

  getAppNotifications(): Observable<Notification[]> {
    const dateTo = new Date();
    const dateFrom = new Date(dateTo);
    dateFrom.setDate(dateFrom.getDate() - 30);

    return this.#apollo
      .query<NotificationQuery>({
        query: getNotificationsQueryGQL,
        variables: {
          notificationInput: {
            dateTo: dateTo,
            dateFrom: dateFrom,
          },
        },
      })
      .pipe(
        map(({ data }) => {
          return data.getNotificationsQuery;
        }),
      );
  }

  getAppNotificationsCount(): Observable<number> {
    const dateTo = new Date();
    const dateFrom = new Date(dateTo);
    dateFrom.setDate(dateFrom.getDate() - 30);

    return this.#apollo
      .query<NotificationQuery>({
        query: getNotificationsCountQueryGQL,
        variables: {
          notificationInput: {
            dateTo: dateTo,
            dateFrom: dateFrom,
            onlyUnread: true,
          },
        },
      })
      .pipe(
        map(({ data }) => {
          return data?.getNotificationsCountQuery.count;
        }),
      );
  }

  markNotificationAsRead(notificationType: NotificationType | null, dateFrom: string | null, dateTo: string | null): Observable<any> {
    return this.#apollo.mutate<boolean>({
      mutation: markNotificationSeenMutationGQL,
      variables: {
        notificationInput: {
          dateTo: dateTo,
          dateFrom: dateFrom,
          seen: true,
          notificationType: notificationType,
        },
      },
    });
  }

  mapNotifications(notifications: Notification[]): AppNotification {
    return notifications.reduce(
      (sorted, notification) => {
        switch (notification.notificationTimeframe) {
          case NotificationTimeframe.TODAY:
            sorted.today!.push(notification);
            break;
          case NotificationTimeframe.YESTERDAY:
            sorted.yesterday!.push(notification);
            break;
          case NotificationTimeframe.OLDER:
            sorted.older!.push(notification);
            break;
        }
        return sorted;
      },
      { today: [], yesterday: [], older: [] } as AppNotification,
    );
  }

  shareProject(shareProjectInput: ShareProject): Observable<ShareProjectResult> {
    return this.#apollo
      .mutate({
        mutation: shareProjectMutationGQL,
        variables: {
          shareProjectInput,
        },
      })
      .pipe(
        map(({ data }) => {
          return data as ShareProjectResult;
        }),
      );
  }
}
