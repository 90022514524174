import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value: string | null | undefined, limit: number): string {
    if (!value) return '';

    if (value.length <= limit || limit < 1) return value;

    const truncatedText = value.substring(0, limit);
    const lastSpaceIndex = truncatedText.lastIndexOf(' ');
    const finalText = lastSpaceIndex > -1 ? truncatedText.substring(0, lastSpaceIndex) : truncatedText;

    return finalText + '...';
  }
}
