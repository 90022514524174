import { EnvironmentFrontend, FrontendBrand } from './environment.model';
import { getEnvironmentValueOrFail } from './utils';

export const getEnvironmentFrontend = (brand: FrontendBrand): EnvironmentFrontend => {
  return {
    production: getEnvironmentValueOrFail('NGX_PRODUCTION') === 'true',
    version: getEnvironmentValueOrFail('NGX_VERSION'),
    didomi_id: getEnvironmentValueOrFail('NGX_DIDOMI_ID'),
    gtag_id: getEnvironmentValueOrFail('NGX_GTAG_ID'),
    endpoints: {
      app: {
        uri: getEnvironmentValueOrFail('NGX_API_URI'),
        Auth: {
          Cognito: {
            userPoolId: getEnvironmentValueOrFail(`NGX_COGNITO_USER_POOL_ID_${brand}`),
            userPoolClientId: getEnvironmentValueOrFail(`NGX_COGNITO_USER_POOL_CLIENT_ID_${brand}`),
          },
        },
      },
      mapbox: {
        geocoding_url: getEnvironmentValueOrFail('NGX_MAPBOX_GEOCODING_URL'),
        access_token: getEnvironmentValueOrFail('NGX_MAPBOX_ACCESS_TOKEN'),
        style: {
          street: getEnvironmentValueOrFail('NGX_MAPBOX_STYLE'),
        },
      },
      strapi: {
        url: getEnvironmentValueOrFail('NGX_STRAPI_URL'),
      },
    },
    features: {
      showCompanies: getEnvironmentValueOrFail('NGX_SHOW_COMPANIES') === 'true',
      showJustAsk: getEnvironmentValueOrFail('NGX_SHOW_JUST_ASK') === 'true',
      showMigrationLogin: getEnvironmentValueOrFail('NGX_SHOW_MIGRATION_LOGIN') === 'true',
      showCurrentStageOnly: getEnvironmentValueOrFail('NGX_SHOW_CURRENT_STAGE_ONLY') === 'true',
      showDemoProfile: getEnvironmentValueOrFail('NGX_SHOW_DEMO_PROFILE') === 'true',
      showSaveSearchNotification: getEnvironmentValueOrFail('NGX_SHOW_SAVE_SEARCH_NOTIFICATION') === 'true',
      showProjectHistorySearch: getEnvironmentValueOrFail('NGX_SHOW_PROJECT_HISTORY_SEARCH') === 'true',
    },
  };
};
