import { Apollo } from 'apollo-angular';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, map, of } from 'rxjs';
import { justAskFeedbackGQL, justAskGQL } from './schemas/just-ask.schema';
import { Feedback, FeedbackInput, JustAskQuery } from './models/just-ask.model';
import { ProjectType } from './models';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class JustAskService {
  readonly #apollo = inject(Apollo);
  readonly #currentLang = inject(TranslateService).currentLang;

  readonly #totalItems = new BehaviorSubject<number>(0);
  totalItems$ = this.#totalItems.asObservable();

  readonly #loading = new BehaviorSubject<boolean | null>(null);
  loading$ = this.#loading.asObservable();

  get totalItems() {
    return this.#totalItems.value;
  }

  set totalItems(value: number) {
    this.#totalItems.next(value);
  }

  get loading() {
    return this.#loading.value;
  }

  set loading(value: boolean | null) {
    this.#loading.next(value);
  }

  ask(query: string, projectType: ProjectType) {
    return this.#apollo
      .query<JustAskQuery>({
        query: justAskGQL,
        fetchPolicy: 'no-cache',
        variables: {
          query,
          projectType,
          lang: this.#currentLang,
        },
      })
      .pipe(map(({ data }) => data.justAskQuery));
  }

  sendFeedback(input: FeedbackInput) {
    return this.#apollo.mutate<boolean>({
      mutation: justAskFeedbackGQL,
      variables: {
        input,
      },
    });
  }
}
