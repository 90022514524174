<ggp-top-bar role="navigation" aria-label="Main Navigation"></ggp-top-bar>

<div class="body-container">
  <div class="left-bar" role="complementary" aria-label="Sidebar Navigation">
    <ggp-left-bar></ggp-left-bar>
  </div>

  <div class="content" role="main" aria-label="Main Content">
    <router-outlet></router-outlet>
  </div>
</div>
