import { gql } from '@apollo/client';

export const justAskGQL = gql`
  query justAskQuery($query: String!, $projectType: ProjectType!, $lang: String!) {
    justAskQuery(query: $query, projectType: $projectType, lang: $lang){
        status
        query
        narrative
        error_message,
        request_id
    }
  }
`;

export const justAskFeedbackGQL = gql`
  mutation submitJustAskFeedback($input: FeedbackInput!) {
    submitJustAskFeedback(input: $input){
      success
    }
  }
`;
