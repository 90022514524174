import { Pipe, PipeTransform } from '@angular/core';
import { IStage, IStagesList, ProjectType, StageType } from '@ggp/generic/shared/util/models';
import { CustomStageType } from '@ggp/generic/shared/services';

@Pipe({
  name: 'publicNoticeStatePipe',
  standalone: true,
})
export class PublicNoticeStatePipe implements PipeTransform {
  transform(projectType: ProjectType, stagesList: IStagesList[], stages: IStage[]): string {
    return (
      stagesList
        .map(stage => this.#getStageState(projectType, stage.id, stages))
        .reverse()
        .find(({ status }) => [CustomStageType.ACTIVE, CustomStageType.ENABLED].includes(status))?.type ?? ''
    );
  }

  #getStageState(
    projectType: ProjectType,
    id: number,
    stages: IStage[],
  ): {
    status: CustomStageType;
    type: StageType | null;
  } {
    let status = CustomStageType.DISABLED;
    let type = null;

    stages.forEach(single => {
      if (single.order === id) {
        status = single.isCurrent ? CustomStageType.ACTIVE : CustomStageType.ENABLED;
        type = single.type;
      } else if (projectType === ProjectType.PRIVATE_PROJECT && single.order > id) {
        status = CustomStageType.ENABLED;
        type = single.type;
      }
    });

    return { status, type };
  }
}
