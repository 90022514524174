import { Pipe, PipeTransform } from '@angular/core';
import { CheckboxItem } from '@ggp/generic/shared/util/models';

@Pipe({
  name: 'checkboxFilter',
  standalone: true,
  pure: false,
})
export class CheckboxFilterPipe implements PipeTransform {
  private readonly MINIMUM_SEARCH_LENGTH = 3;

  transform(items: CheckboxItem[], searchTerm = ''): CheckboxItem[] {
    const trimmedSearch = searchTerm?.trim() || '';

    if (trimmedSearch.length === 0 || trimmedSearch.length < this.MINIMUM_SEARCH_LENGTH) {
      return items;
    }

    const normalizedSearch = trimmedSearch.toLowerCase();
    return items.filter(item => item.label.toLowerCase().includes(normalizedSearch));
  }
}
