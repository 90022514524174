/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, concatMap, finalize, forkJoin, map, of, Subject, takeUntil } from 'rxjs';

import { EventEmitter, inject, Injectable, OnDestroy } from '@angular/core';
import { Filters, Language, SavedFilters, Scope, Stage } from '@ggp/generic/shared/util/models';
import { TranslateService } from '@ngx-translate/core';

import { FilterService } from './filter.service';
import { LocaleLanguageService } from './locale-language.service';
import { FilterSelected, UserProfile } from './models';
import { UserProfilesQuery } from './query/userProfilesQuery';
import { deleteProfileMutationGQL, getProfileByIdQueryGQL, getUserProfilesQueryGQL, saveProfileMutationGQL } from './schemas/userProfiles.schema';

interface Profile {
  profileId: string;
  profileName: string;
  notify: boolean;
  filters: FilterSelected[];
}

enum LanguageValue {
  en = 'English',
  fr = 'French',
  nl = 'Dutch',
  de = 'German',
}

enum TreeType {
  locations = 'region',
  classes = 'class',
  activities = 'activities',
  buildingTypes = 'building_type',
  cpvs = 'cpv',
}

@Injectable({
  providedIn: 'root',
})
export class SaveSearchService implements OnDestroy {
  readonly #apollo = inject(Apollo);
  readonly #filterService = inject(FilterService);
  readonly #localeLanguageService = inject(LocaleLanguageService);
  readonly #translateService = inject(TranslateService);
  readonly #onDestroy = new Subject();

  currentSavedSearch: Profile = { profileId: '', profileName: '', notify: false, filters: [] };
  savedSearchSelectedEvent: EventEmitter<string> = new EventEmitter<string>();

  #listUpdatedSubject = new Subject<number>();
  listUpdated$ = this.#listUpdatedSubject.asObservable();

  profileSearchIdsSubject$ = new BehaviorSubject<number[] | null>(null);
  profileSearchSubject$ = new BehaviorSubject<UserProfile[] | null>(null);

  isTreeFilterReady$ = new BehaviorSubject<boolean>(true);

  readonly #treeKeys = ['locations', 'classes', 'activities', 'buildingTypes', 'cpvs'];

  getSearchById(profileId: string) {
    return this.#apollo
      .query<UserProfilesQuery>({
        query: getProfileByIdQueryGQL,
        variables: {
          profileId,
          language: this.#localeLanguageService.getLocaleLanguage(),
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data }) => {
          return data?.getProfileByIdQuery;
        }),
      );
  }

  saveSearch(language: Language, saveSearch: any) {
    return this.#apollo
      .mutate({
        mutation: saveProfileMutationGQL,
        variables: {
          language,
          saveSearch,
        },
      })
      .pipe(
        concatMap((result: any) => {
          this.currentSavedSearch.filters = JSON.parse(JSON.stringify(this.#filterService.getCopyOfFilters()));
          this.#listUpdatedSubject.next(saveSearch.description);
          return of(result);
        }),
      );
  }

  getAllProfiles(projectType: string) {
    return this.#apollo
      .query<UserProfilesQuery>({
        query: getUserProfilesQueryGQL,
        variables: {
          projectType,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data }) => {
          return data?.getUserProfilesQuery;
        }),
      );
  }

  showSearch(profileDetail: any, folderId: string, displaySearch: boolean, removedSearchProfilFilter = false) {
    this.isTreeFilterReady$.next(false);
    if (!profileDetail) return;

    this.currentSavedSearch = { profileId: folderId, profileName: profileDetail?.description, notify: profileDetail?.notify, filters: [] };
    const filtersJson = JSON.parse(profileDetail?.profile_value);
    const keys = Object.keys(filtersJson);

    if (removedSearchProfilFilter) {
      const singleFilter: FilterSelected = {
        parentKey: 'subscription_data',
        value: this.profileSearchIdsSubject$.value,
        displayText: this.#translateService.instant('FILTER.ALL_SUBSCRIPTION_DATA'),
      };
      this.#filterService.addFilter(singleFilter);
      this.isTreeFilterReady$.next(true);
      return;
    }

    keys.map((key: string) => {
      switch (key) {
        case 'profiles':
          this.formatProfileFilter(filtersJson, displaySearch);
          break;

        case 'read':
          if (displaySearch) {
            const newFilter = this.formatFilters(filtersJson[key], key, keys);
            this.#filterService.updateFilter(newFilter);
          }
          break;
        case 'isDemoProfile':
          this.#filterService.updateFilter(this.formatFilters(filtersJson[key], key, keys));
          break;
        case 'scopes':
          break;
        case 'deadline':
        case 'lastModificationDate':
        case 'publishDate':
          if (filtersJson[key].dateFrom && filtersJson[key].dateTo && displaySearch) {
            const newFilter = this.formatFilters(filtersJson[key], key, keys);
            this.#filterService.updateFilter(newFilter);
          }
          break;

        default:
          if (displaySearch && this.isNotTreeFilter(key) && filtersJson[key].length) {
            filtersJson[key].map((keyFilter: any) => {
              const newFilter = this.formatFilters(keyFilter, key, keys);
              this.#filterService.addFilter(newFilter);
            });
          }

          break;
      }
    });

    if (filtersJson.scopes.length) {
      filtersJson.scopes.forEach((scope: Scope) => this.#filterService.addListByKeyParent({ parentKey: 'scopes', value: scope }));
    }

    const extractedData = this.filterAndTransformData(filtersJson);
    this.formatTrees(extractedData, displaySearch);

    this.currentSavedSearch.filters = JSON.parse(JSON.stringify(this.#filterService.getCopyOfFilters()));
    if (displaySearch) {
      this.savedSearchSelectedEvent.emit('my_searches');
    }
  }

  isNotTreeFilter(key: string): boolean {
    return !this.#treeKeys.includes(key);
  }

  filterAndTransformData(filters: any) {
    return Object.entries(filters)
      .filter(([key]) => this.#treeKeys.includes(key))
      .map(([key, value]) => {
        return (value as string[]).length > 0
          ? {
              name: key === 'locations' ? 'execution_site' : key === 'buildingTypes' ? 'building_type' : key,
              type: this.getTypeFromKey(key),
              value: (value as any).map((el: any) => (key === 'locations' ? el.value : el.key)),
            }
          : null;
      })
      .filter(item => item !== null)
      .flat();
  }

  private getTypeFromKey(key: string): TreeType {
    return TreeType[key as keyof typeof TreeType] || null;
  }

  formatFilters(keyFilter: any, key: string, allKeys: string[]): FilterSelected {
    let singleFilter: FilterSelected = {
      parentKey: key,
      value: keyFilter.key || keyFilter,
      displayText: keyFilter.value || keyFilter,
    };

    switch (key) {
      case 'keywords':
        singleFilter.parentKey = 'keyword';
        singleFilter.value = keyFilter.word;
        singleFilter.displayText = keyFilter.word.replace(/\\/g, '');
        break;

      case 'languages':
        singleFilter.parentKey = 'language';
        singleFilter.displayText = LanguageValue[keyFilter as keyof typeof LanguageValue];
        break;

      case 'publishDate':
      case 'lastModificationDate':
        singleFilter = {
          parentKey: 'last_update',
          value: {
            minValue: new Date(keyFilter.dateFrom),
            maxValue: new Date(keyFilter.dateTo),
            type: this.getLastUpdateType(allKeys),
          },
          displayText: 'FILTER.LAST_UPDATE_FILTER',
        };
        break;

      case 'tags':
        singleFilter.value = keyFilter.value;
        singleFilter.color = keyFilter.color;
        break;

      case 'deadline':
        singleFilter = {
          parentKey: 'deadline',
          value: {
            minValue: new Date(keyFilter.dateFrom),
            maxValue: new Date(keyFilter.dateTo),
          },
          displayText: 'FILTER.DEADLINE_FILTER',
        };
        break;

      case 'startDate':
        singleFilter = {
          parentKey: 'start_date',
          value: {
            minValue: new Date(keyFilter.dateFrom),
            maxValue: new Date(keyFilter.dateTo),
          },
          displayText: 'FILTER.START_DATE_FILTER',
        };
        break;

      case 'scopes':
        singleFilter = {
          parentKey: 'scopes',
          value: {
            minValue: keyFilter.minValue,
            maxValue: keyFilter.maxValue,
            optional: keyFilter.optional,
          },
          displayText: 'FILTER.DEADLINE_FILTER',
        };
        break;

      case 'projectTypes':
        singleFilter.parentKey = 'project_type';
        singleFilter.value = keyFilter.key;
        break;

      case 'stages':
        singleFilter.parentKey = 'procedure_type';
        singleFilter.value = keyFilter.stage;
        singleFilter.displayText = keyFilter.stage;
        singleFilter.currentStageOnly = keyFilter.currentStageOnly;
        break;

      case 'read':
        singleFilter = {
          parentKey: 'read_unread',
          value: keyFilter,
          displayText: this.#translateService.instant(keyFilter ? 'FILTER.READ_UNREAD.READ_STATUS' : 'FILTER.READ_UNREAD.UNREAD_STATUS'),
        };
        break;

      default:
        break;
    }

    return singleFilter;
  }

  formatTrees(filterInfo: any[], displaySearch: boolean) {
    const currentLang = this.#translateService.currentLang;

    const requests$ = filterInfo.map((filterInfo: any) =>
      this.#filterService.getTreeData(filterInfo.type, currentLang).pipe(
        takeUntil(this.#onDestroy),
        map(response => {
          return { filterInfo: filterInfo, data: response };
        }),
      ),
    );

    // Wait for all requests to complete using concat
    forkJoin(requests$)
      .pipe(finalize(() => this.isTreeFilterReady$.next(true)))
      .subscribe(responses => {
        responses.forEach(data => {
          const newSelectedElements = this.loopOverChildren(data.data, data.filterInfo.value);
          newSelectedElements.newSelected.map((el, index) => {
            const filter: FilterSelected = {
              parentKey: data.filterInfo.name,
              value: el.key,
              displayText: el.title,
            };
            if (index === 0) {
              filter.children = newSelectedElements.selectedChildren;
            }
            if (displaySearch) {
              this.#filterService.addFilter(filter);
            }
          });
        });
        this.currentSavedSearch.filters = JSON.parse(JSON.stringify(this.#filterService.getCopyOfFilters()));
        if (displaySearch) {
          this.savedSearchSelectedEvent.emit('my_searches');
        }
      });
  }

  emptyCurrentSavedSearch() {
    this.currentSavedSearch = { profileId: '', profileName: '', notify: false, filters: [] };
  }

  loopOverChildren(tree: any[], selectedElements: string[]) {
    const newSelected: any[] = [];
    const selectedChildren: string[] = [];
    function checkSelection(node: any, type: 'parent' | 'children') {
      const isSelected = selectedElements?.includes(node.key);
      const childrenAllSelected = node.children.length && node.children.every((child: any) => selectedElements?.includes(child.key));

      if (isSelected && childrenAllSelected) {
        if (type === 'parent') {
          newSelected.push({
            key: node.key,
            title: node.title,
          });
        }
        node.children.map((el: any) => {
          selectedChildren.push(el.key);
          checkSelection(el, 'children');
        });
      } else if (!isSelected && node.children.length) {
        node.children.map((el: any) => checkSelection(el, 'parent'));
      } else if (isSelected) {
        if (type === 'parent') {
          newSelected.push({
            key: node.key,
            title: node.title,
          });
        }
      }
    }
    tree.map(treeNode => checkSelection(treeNode, 'parent'));
    return { newSelected, selectedChildren };
  }
  private getLastUpdateType(keys: string[]): string {
    if (keys.includes('lastModificationDate') && keys.includes('publishDate')) {
      return 'FIRST_TIME_PUBLICATIONS_UPDATES';
    } else if (keys.includes('lastModificationDate')) {
      return 'UPDATES_ONLY';
    }
    return 'FIRST_TIME_PUBLICATIONS_ONLY';
  }

  formatProfileFilter(filtersJson: any, displaySearch: boolean) {
    if (!displaySearch) {
      return;
    }
    filtersJson['profiles']?.map((value: string) => {
      this.#filterService.addFilter({
        parentKey: 'subscription_data',
        value,
        displayText: this.profileSearchSubject$.getValue()?.find(profile => profile.profileId === +value)?.profileName,
      });
    });
  }

  resetSavedSearchFilter() {
    this.#filterService.clearAll();
    this.#filterService.addListByKeyParent(JSON.parse(JSON.stringify(this.currentSavedSearch?.filters)));
    this.savedSearchSelectedEvent.emit('my_searches');
  }

  deteleProfile(profileId: number) {
    return this.#apollo
      .mutate({
        mutation: deleteProfileMutationGQL,
        variables: {
          profileId,
        },
      })
      .pipe(
        concatMap((result: any) => {
          this.#listUpdatedSubject.next(profileId);
          return of(result);
        }),
      );
  }

  mapJustAskFilters(filters: SavedFilters) {
    const filtersJson = filters;
    const keys = Object.keys(filtersJson);

    keys.map((key: string) => {
      switch (key) {
        case 'deadline':
        case 'lastModificationDate':
        case 'publishDate':
          if (filtersJson[key]?.dateFrom && filtersJson[key]?.dateTo) {
            const newFilter = this.formatFilters(filtersJson[key], key, keys);
            this.#filterService.updateFilter(newFilter);
          }
          break;
        case 'stages': {
          const potentialStagesFilter = filtersJson[key];
          if (this.isStages(potentialStagesFilter)) {
            const formattedStageFilter = this.formatFilters(potentialStagesFilter, key, keys);
            this.#filterService.addFilter(formattedStageFilter);
          }
          break;
        }

        default:
          if (this.isNotTreeFilter(key) && Array.isArray(filtersJson[key])) {
            (filtersJson[key] as string[] | Stage[])?.map((keyFilter: string | Stage) => {
              const newFilter = this.formatFilters(keyFilter, key, keys);
              this.#filterService.addFilter(newFilter);
            });
          }

          break;
      }
    });

    const extractedData = this.filterAndTransformData(filtersJson);
    this.formatTrees(extractedData, true);
  }

  isStages(value: unknown): value is Stage[] {
    return (
      Array.isArray(value) &&
      (value as []).length > 0 &&
      (value as Stage[]).every(s => typeof s === 'object' && s !== null && 'stage' in s && (!s.currentStageOnly || typeof s.currentStageOnly === 'boolean'))
    );
  }

  mapProfileValueFilters(value: string): Filters {
    const profileValue = JSON.parse(value);

    return {
      activities: profileValue.activities?.map(({ key }: { key: string }) => key),
      buildingTypes: profileValue.buildingTypes?.map(({ key }: { key: string }) => key),
      classes: profileValue.classes?.map(({ key }: { key: string }) => key),
      cpvs: profileValue.cpvs?.map(({ key }: { key: string }) => key),
      deadlineDate: profileValue.deadline,
      isDemoProfile: profileValue.isDemoProfile,
      keywords: profileValue.keywords?.map(({ word }: { word: string }) => word),
      languages: profileValue.languages,
      lastUpdateDate: profileValue.lastUpdateDate,
      profiles: profileValue.profiles,
      projectTypes: profileValue.projectTypes?.map(({ key }: { key: string }) => key),
      read: profileValue.read,
      regions: profileValue.locations?.map(({ value }: { value: string }) => value),
      stages: profileValue.stages,
      startDate: profileValue.startDate,
      tags: profileValue.tags?.map(({ value }: { value: string }) => value),
      scopes: profileValue.scopes?.map(({ value }: { value: Scope }) => value),
    };
  }

  ngOnDestroy(): void {
    this.#onDestroy.next(null);
    this.#onDestroy.complete();
  }
}
