import { inject, Pipe, PipeTransform } from '@angular/core';
import { ProjectSizeFilterKeys } from '@ggp/generic/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedNumberPipe } from '@ggp/generic/shared/pipes';
import { Scope } from '@ggp/generic/shared/util/models';

type ProjectSizeFilterKey = `${ProjectSizeFilterKeys}`;

const translationKeys: Record<ProjectSizeFilterKey, string> = {
  [ProjectSizeFilterKeys.SURFACE]: 'SURFACE',
  [ProjectSizeFilterKeys.COST_ESTIMATE]: 'COST_ESTIMATE',
  [ProjectSizeFilterKeys.BUILDINGS_NUMBER]: 'BUILDINGS_NUMBER',
  [ProjectSizeFilterKeys.FLOORS_NUMBER]: 'FLOORS_NUMBER',
  [ProjectSizeFilterKeys.HOUSES_NUMBER]: 'HOUSES_NUMBER',
  [ProjectSizeFilterKeys.DEMOLITION_VOLUME]: 'DEMOLITION_VOLUME',
};

@Pipe({
  name: 'scopes',
  standalone: true,
})
export class ScopesPipe implements PipeTransform {
  readonly #translateService = inject(TranslateService);
  readonly #localizedNumberPipe = inject(LocalizedNumberPipe);

  transform({ scope, minValue, maxValue, optional }: Scope): string {
    const translationKey = this.#translateService.instant('PROJECT_DETAILS.' + translationKeys[scope as ProjectSizeFilterKey]);
    const currency = scope === ProjectSizeFilterKeys.COST_ESTIMATE ? '€' : '';
    const appendix = [ProjectSizeFilterKeys.DEMOLITION_VOLUME, ProjectSizeFilterKeys.SURFACE].includes(scope as ProjectSizeFilterKeys) ? 'm²' : '';

    const min = this.formatNumber(minValue);
    const max = this.formatNumber(maxValue);
    let result = `${translationKey}: `;

    if (minValue && !maxValue) {
      result += this.#translateService.instant('FILTER.PROJECT_SIZE_FILTER.STARTING_FROM', {
        min,
        currency,
        appendix,
      });
    } else if (maxValue && !minValue) {
      result += this.#translateService.instant('FILTER.PROJECT_SIZE_FILTER.UP_TO', {
        max,
        currency,
        appendix,
      });
    } else if (minValue && maxValue) {
      result += this.#translateService.instant('FILTER.PROJECT_SIZE_FILTER.SYMBOL', {
        min,
        max,
        currency,
        appendix,
      });
    }

    if (optional) {
      result += ` (${this.#translateService.instant('FILTER.INCLUDE_PROJECTS_NO_VALUE')})`;
    }

    return result.trim();
  }

  private formatNumber(value: string | undefined) {
    return value !== undefined ? this.#localizedNumberPipe.transform(value) : '';
  }
}
