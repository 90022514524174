import { concatMap, from, map, Observable, toArray } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { GeoCodingService } from '@ggp/generic/shared/services';
import { ExecutionSite, ExecutionSiteLocations } from '@ggp/generic/shared/util/models';

@Injectable({
  providedIn: 'root',
})
export class GeoCodingToLocationService {
  readonly #geocoding = inject(GeoCodingService);

  getLocationsArray(array: ExecutionSite[]): Observable<ExecutionSiteLocations[]> {
    return from(array).pipe(
      concatMap((element: ExecutionSite) => {
        return this.#geocoding
          .getLocationByText({
            searchText: this.getSearchText(element),
            country: element.country.code,
            address: element.address,
          })
          .pipe(map(result => ({ element, result })));
      }),
      toArray(),
    );
  }

  private getSearchText(element: ExecutionSite) {
    return element.postCode
      ? element.type === 'POSTCODE'
        ? this.extractPostcode(element.postCode)
        : element.postCode
      : element.region
      ? element.region.code
      : element.country.code;
  }

  private extractPostcode(label: string) {
    return label.split('-')[0].trim();
  }

  setLocationsModel(results: ExecutionSiteLocations[]) {
    results.forEach(({ element, result }) => {
      result?.subscribe((newResult) => {
        if (newResult) {
          element.location = {
            lat: newResult[1],
            lng: newResult[0],
          };
        }
      });
    });
    return results;
  }
}
